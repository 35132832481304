<template>
  <br />
  <br />
  <br />
  <!-- Begin page content -->
  <div class="container">

    <ul class="breadcrumb">
      <li class="active">Principal</li>
    </ul>

    <div class="page-header" style="text-align:center;">
      <h2>Sistema de controle</h2>
    </div>

    <div style="margin: 10px auto; text-align: center;">
      <img src="@/assets/logo.png" width="400" />
    </div>
    </div>
  <!--/.End page content -->
</template>

<script>

// import LayoutHeader from "@/views/layout/layout-site-header";
// import LayoutFooter from "@/views/layout/layout-site-footer";

export default {
  components: {
    // LayoutHeader,
    // LayoutFooter,
  },

  setup() {},
};
</script>

<style>
</style>
